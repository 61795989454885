import React, { useMemo } from "react";
import tw, { styled } from "twin.macro";
import { graphql, Link, navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import _ from "lodash";
import Layout from "../components/Layout";
import Header from "@shared/components/Header";
import { MAIN_COLOR, SITE } from "../config";

const ListContainer = styled.div`
  ${tw`flex-1 w-full p-5`}
`;

const HeaderTitle = styled.h3`
  ${tw`text-2xl mt-5 mb-7 capitalize`}
`;

const HeaderDescription = styled.p`
  ${tw`mt-4 mb-8 text-gray-500`}
`;

const Body = styled.div`
  ${tw``}
`;

const CardContainer = styled.div`
  ${tw`
    // grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3
    flex flex-wrap justify-start
    gap-y-2.5 -mx-2.5
  `}
`;

const Card = styled.div`
  ${tw`
    flex flex-col
    mx-2.5
    w-full md:w-[calc(50% - 1.25rem)] lg:w-[calc(33.33% - 1.25rem)]
  `}
`;
const ImageContainer = styled.div`
  ${tw`
    h-[250px] overflow-hidden 
    hover:brightness-75 cursor-pointer
    transition-[filter] duration-200
  `}
`;

const CardContent = styled.div`
  ${tw`w-full flex flex-col grow`}
`;
const CardHeader = styled.h3`
  ${tw`text-2xl my-4 whitespace-nowrap overflow-hidden text-ellipsis`}
`;
const CardTitle = styled.h3`
  ${tw`text-2xl my-4 mx-2`}
`;

const CardDescription = styled.p`
  ${tw`mb-5 mt-2 text-gray-500 grow text-base mx-0`}
`;

const CardActions = styled.div`
  ${tw`flex flex-wrap gap-4 mx-2 justify-center`}
`;

const LinkButton = styled(Link)`
  ${tw`
    px-3 py-1.5 rounded
    bg-[#EC1F27] hover:bg-red-700
    transition-[background-color] duration-200
    text-white font-bold text-sm
  `}
`;
const StyledText = styled.span`
  ${tw`font-bold text-[#EC1F27]`}
`;

const ListPage = ({
  data: {
    markdownRemark: {
      frontmatter: {
        seo,
        listCategory,
        hero,
        tabs,
        title,
        description,
        externalPages,
      },
    },
    listItems: { nodes },
    alumniListItems: { nodes: alumniNodes },
    scheduleListItems: { nodes: scheduleNodes },
  },
  location,
}) => {
  const orderedListItems = useMemo(() => {
    const mdPages = nodes.map((page) => ({
      ...page.frontmatter,
      ...page.fields,
    }));
    return _.orderBy([...mdPages, ...externalPages], ["order", "title"]);
  }, [nodes]);

  const orderedAlumniItems = useMemo(() => {
    const mdPages = alumniNodes.map((page) => ({
      ...page.frontmatter,
      ...page.fields,
      excerpt: page.excerpt,
    }));
    return _.orderBy([...mdPages, ...externalPages], ["order", "title"]);
  }, [alumniNodes]);

  const orderedScheduleItems = useMemo(() => {
    const mdPages = scheduleNodes.map((page) => ({
      ...page.frontmatter,
    }));
    return _.orderBy([...mdPages, ...externalPages], ["order"]);
  }, [scheduleNodes]);

  console.log(orderedScheduleItems)

  return (
    <Layout
      seoTitle={seo?.title || title}
      seoDescription={seo?.description}
      location={location}
    >
      <ListContainer>
        {title && (
          <Header
            title={title}
            heroData={hero}
            tabsData={tabs}
            color={MAIN_COLOR}
            site={SITE}
          />
        )}
        <Body>
          {description && <HeaderDescription>{description}</HeaderDescription>}
          <CardContainer>
            {orderedListItems.map((item, idx) => {
              const { title, description, hero, slug, url, image } = item;
              const { listImage, pageImage } = hero || {};

              const thumbnail = getImage(
                image
                  ? image
                  : listImage || _.isNil(pageImage)
                  ? listImage
                  : pageImage
              );

              return (
                <Card key={idx}>
                  <CardHeader>{title}</CardHeader>

                  <ImageContainer>
                    {slug && (
                      <Link to={slug}>
                        <GatsbyImage
                          image={thumbnail}
                          alt={title || "thumbnail"}
                          style={{ width: "100%", height: "100%" }}
                          imgStyle={{ width: "100%", height: "100%" }}
                          objectFit="cover"
                          objectPosition="50% 50%"
                        />
                      </Link>
                    )}
                    {url && (
                      <a href={url} target="_blank" rel="noreferrer">
                        <GatsbyImage
                          image={thumbnail}
                          alt={title || "thumbnail"}
                          style={{ width: "100%", height: "100%" }}
                          imgStyle={{ width: "100%", height: "100%" }}
                          objectFit="cover"
                          objectPosition="50% 50%"
                        />
                      </a>
                    )}
                  </ImageContainer>
                  <CardContent>
                    <CardDescription>
                      {description}{" "}
                      {slug && (
                        <Link to={slug}>
                          <StyledText>read more</StyledText>
                        </Link>
                      )}
                      {url && (
                        <a href={url} target="_blank" rel="noreferrer">
                          <StyledText>read more</StyledText>
                        </a>
                      )}
                    </CardDescription>
                    {/* 
                        <CardActions>
                          <LinkButton to={slug}>Learn More</LinkButton>
                          {directionUrl && (
                            <LinkButton to={directionUrl}>
                              Get Directions
                            </LinkButton>
                          )}
                        </CardActions>
                      */}
                  </CardContent>
                </Card>
              );
            })}
            {listCategory === "alumni" &&
              orderedAlumniItems.map((item, idx) => {
                const { title, description, hero, slug, excerpt } = item;
                const { listImage, pageImage } = hero || {};

                const thumbnail = getImage(
                  listImage || _.isNil(pageImage) ? listImage : pageImage
                );

                return (
                  <Card key={idx}>
                    <CardHeader>{title}</CardHeader>

                    <ImageContainer>
                      {slug && (
                        <Link to={slug}>
                          <GatsbyImage
                            image={thumbnail}
                            alt={title || "thumbnail"}
                            style={{ width: "100%", height: "100%" }}
                            imgStyle={{ width: "100%", height: "100%" }}
                            objectFit="cover"
                            objectPosition="50% 50%"
                          />
                        </Link>
                      )}
                    </ImageContainer>
                    <CardContent>
                      <CardDescription>
                        {description || excerpt}{" "}
                        {slug && (
                          <Link to={slug}>
                            <StyledText>read more</StyledText>
                          </Link>
                        )}
                      </CardDescription>
                    </CardContent>
                  </Card>
                );
              })}

            {listCategory === "schedule" &&
              orderedScheduleItems.map((item, idx) => {
                const { id, name, description, hero: scheduleItemHero } = item;
                const { listImage, pageImage } = scheduleItemHero || {};

                const thumbnail = getImage(
                  listImage ? listImage : pageImage ? pageImage : hero.pageImage
                );

                return (
                  <Card key={id}>
                    <CardHeader>{name} Schedule</CardHeader>

                    <ImageContainer>
                      <Link
                        to={`/schedule?team=${name
                          .toLowerCase()
                          .replace(" ", "-")}`}
                      >
                        <GatsbyImage
                          image={thumbnail}
                          alt={name || "thumbnail"}
                          style={{ width: "100%", height: "100%" }}
                          imgStyle={{ width: "100%", height: "100%" }}
                          objectFit="cover"
                          objectPosition="50% 50%"
                        />
                      </Link>
                    </ImageContainer>
                    <CardContent>
                      <CardDescription>
                        {description || ""}{" "}
                        <Link
                          to={`/schedule?team=${name
                            .toLowerCase()
                            .replace(" ", "-")}`}
                        >
                          <StyledText>view schedule</StyledText>
                        </Link>
                      </CardDescription>
                    </CardContent>
                  </Card>
                );
              })}
          </CardContainer>
        </Body>
      </ListContainer>
    </Layout>
  );
};

export default ListPage;

export const pageQuery = graphql`
  query ListPageQuery($id: String, $listCategory: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        listCategory
        seo {
          title
          description
        }
        hero {
          pageImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          pageImagePosition
        }
        tabs {
          label
          url
          icon
        }
        externalPages {
          title
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          description
          url
          order
        }
      }
    }
    listItems: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "inner-page-1" }
          category: { eq: $listCategory }
        }
      }
      sort: { fields: frontmatter___title, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          order
          description
          templateKey
          seo {
            title
            description
          }
          hero {
            pageImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            listImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          directionUrl
        }
        excerpt
        fields {
          slug
        }
      }
    }
    alumniListItems: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "alumni-page" }
          category: { eq: "alumni" }
        }
      }
      sort: { fields: frontmatter___title, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          order
          description
          templateKey
          seo {
            title
            description
          }
          hero {
            pageImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            listImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        excerpt
        fields {
          slug
        }
      }
    }
    scheduleListItems: allMarkdownRemark(
      filter: {
        frontmatter: { mdType: { eq: "team" }, createPage: { eq: true } }
      }
      sort: { fields: frontmatter___title, order: DESC }
    ) {
      nodes {
        frontmatter {
          id
          name
          description
          order
          hero {
            pageImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            listImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`;
